import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import BootstrapProvider from "@bootstrap-styled/provider";
import * as serviceWorker from "./serviceWorker";
import theme from "./modules/theme";
import { Normalize } from "styled-normalize";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import purple from "@material-ui/core/colors/purple";
import { Provider } from "react-redux";
import { store } from "./store";
import "@react-page/editor/lib/index.css";
import "grapesjs/dist/css/grapes.min.css";

const muiTheme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: purple,
  },
  status: {
    danger: "orange",
  },
  typography: {
    fontFamily: ["HK Grotesk"].join(","),
  },
});

ReactDOM.render(
  <Provider store={store} onUpdate={() => window.scrollTo(0, 0)}>
    <ThemeProvider theme={muiTheme}>
      <BootstrapProvider theme={theme}>
        <Normalize />
        <App />
      </BootstrapProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
