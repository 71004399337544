import * as actionType from "./actions";
import { reAuthenticate } from "../services/services";
const initialState = {
  collapsedMenu: false,
  updated: false,
  dropMenu: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.COLLAPSE_MENU:
      return {
        ...state,
        collapsedMenu: !state.collapsedMenu
      };
      break;
    case actionType.DROP_MENU:
      return {
        ...state,
        dropMenu: action.dropMenu
      };
      break;
    case actionType.UPDATE_LIST:
      return {
        ...state,
        updated: action.updated
      };
      break;
    case actionType.CHAGE_PATH:
      return {
        ...state,
        pathname: action.pathname
      };
      break;
    case actionType.CHAGE_PAGE:
      return {
        ...state,
        current: action.current
      };
      break;
    case actionType.AUTHENTICATION:
      return {
        ...state,
        user: action.user
      };
      break;
    case actionType.NOTIFICATION:
      return {
        ...state,
        notify: action.notify
      };
      break;
    case actionType.ADD_TASK:
      return {
        ...state,
        task: action.task
      };
      break;
    case actionType.UPDATE_TASKS:
      let { data } = action;
      console.log("QUE VIEN AQUI!", action);
      return {
        ...state,
        task: data.log
      };
      break;
    case actionType.COLOR_THEME:
      let { theme } = action;
      return {
        ...state,
        theme
      };
      break;
    case actionType.RE_AUTHENTICATION:
      reAuthenticate()
        .then(response => {
          console.log("Si wuapió!");
        })
        .catch(err => console.log("No wapió!"));
      return {
        ...state,
        user: action.user
      };
      break;
    case actionType.CHANGE_SEARCH:
      return {
        ...state,
        filter: action.filter
      };
      break;
    case actionType.CHANGE_FILTERS:
      return {
        ...state,
        defaultFilters: action.defaultFilters
      };
      break;
    case actionType.LINKED_PRODUCTS:
      return {
        ...state,
        slugPath: action.slugPath
      };
      break;
    default:
      return state;
  }
};

export default reducer;
