const theme = {
  '$brand-danger': 'green',
  '$btn-padding-y-lg': '.721rem',
  '$btn-padding-x': '1.5rem',
  '$btn-padding-y': '.625rem',
  '$btn-primary-bg': '#0cc665',
  //' transparent linear-gradient(91deg, #0092D0 0%, #734DFF 100%) 0% 0% no-repeat padding-box;',
  '$btn-secondary-bg':
    ' transparent linear-gradient(90deg, #00A650 0%, #00B2E3 100%) 0% 0% no-repeat padding-box;',
  '$btn-success-bg':
    'transparent linear-gradient(90deg, #8DC63F 0%, #00A650 100%) 0% 0% no-repeat padding-box',
  '$btn-border-width': '0',
  '$link-hover-decoration': 'none',
  '$navbar-padding-y': '0.813rem',
  '$font-size-h1': '2.8125rem',
  '$card-border-width': '0',
  '$text-muted': 'rgba(51,51,51,0.5)',
};

export default theme;
