import React, { useEffect, useRef, useState } from "react";
/* import NavBar from "./components/NavBar"; */
import { Router, createHistory, navigate, Redirect } from "@reach/router";
import Loadeable from "react-loadable";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "codemirror/lib/codemirror.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { socket } from "./api";
/* import * as messages from "./constants/messages"; */
import NotFound from "./views/NotFound";
/* import { retryLoginAndGetCurrentUser } from "./actions/auth"; */
import qs from "qs";
/* Redux Store */
import { store } from "./store";
import { Provider, useDispatch, useSelector } from "react-redux";
/* Redux */
import { connect } from "react-redux";
import * as actionTypes from "./store/actions";

import Loader from "./components/loader";
/* Fonts */
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
/* Import Services */
import {
  reAuthenticate,
  current,
  tasks,
  LogActions
} from "./services/services";
import { message, notification } from "antd";

import Markdown from "./components/markdown/Markdown";
/* Multi language */
import { I18n } from "react-polyglot";
/* import messages from './language'; */
/* import DashboardLayout from "./layouts/dashboard_layout"; */

/*para el image handler*/

/*firebase daniel start*/
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getService, OrderService } from "./services/";
const firebaseConfig = {
  apiKey: "AIzaSyATXJS7wV40f3fuuDT8yOCBuK0NizYoHfQ",
  authDomain: "poketmenu.firebaseapp.com",
  projectId: "poketmenu",
  storageBucket: "poketmenu.appspot.com",
  messagingSenderId: "413843227515",
  appId: "1:413843227515:web:685aeacdfe0acbc48873de",
  measurementId: "G-6YZ173395S"
};
const fapp = initializeApp(firebaseConfig);
const messaging = getMessaging(fapp);

onMessage(messaging, payload => {
  console.log("----> payload: ", payload);
  let { data } = payload;
  /* if (data && data.order) {
    window.focus();
    window.location.href = `http://localhost:3000/dashboard/orders?order_id=${data.order}`
    alert(JSON.stringify(data));
  } */
});

/*firebase daniel end*/
window.imageShark = (url, width, height) =>
  btoa(
    JSON.stringify({
      bucket: "menuapps3",
      key: url,
      edits: { resize: { width, height, fit: "cover" } }
    })
  );
window.imageSharkOriginSize = url =>
  btoa(JSON.stringify({ bucket: "menuapps3", key: url }));

/* fin para el image handler*/

const messages = {
  es: {},
  en: {}
};

library.add(fab, fas, faCheckSquare, faCoffee);
/* Import Components */
const DashboardLayout = Loadeable({
  loader: () => import("./layouts/dashboard_layout"),
  loading: Loader
});
const MainLayout = Loadeable({
  loader: () => import("./layouts/admin_layout"),
  loading: Loader
});
const SignIn = Loadeable({
  loader: () => import("./views/authentication/signin/SignIn"),
  loading: Loader
});
const SignUp = Loadeable({
  loader: () => import("./views/authentication/signup/Signup"),
  loading: Loader
});
const ResetPassword = Loadeable({
  loader: () => import("./views/authentication/reset-password/ResetPassword"),
  loading: Loader
});
const ChangePassword = Loadeable({
  loader: () => import("./views/authentication/change-password/ChangePassword"),
  loading: Loader
});

const Confirmation = Loadeable({
  loader: () => import("./views/authentication/confirmation/Confirmation"),
  loading: Loader
});

String.prototype.capitalize = function() {
  let str = this.toString();
  let regex = /(\s[\w])+/g;
  let match = str.match(regex);
  if (match) {
    match.forEach(key => {
      if (key) str = str.replace(key, key.toUpperCase());
    });
  }
  if (str[0]) return str[0].toUpperCase() + str.slice(1, str.length);
};
String.prototype.format = function() {
  let str = this;
  for (let key in arguments) {
    let value = arguments[key];
    if (typeof value == "object") {
      let match = str.match(/\{(\w+\.?\w+)\}/g);
      if (match) {
        match.forEach(k => {
          k = k.replace(/\{|\}/g, "");
          let val = k.split(".").reduce((prev, current) => {
            return prev[current] ? prev[current] : prev;
          }, value);
          str = str.replace("{" + k + "}", val);
        });
      }
    } else {
      str = str.replace("{" + key + "}", value);
    }
  }
  return str;
};
library.add(fab, fas, faCheckSquare, faCoffee);

const Root = styled.div`
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;

  /* @font-face {
    font-family: "Poppins";
    src: url("/fonts/HKGrotesk-Medium.otf");
    font-weight: normal;
  }
  @font-face {
    font-family: "Poppins";
    src: url("/fonts/HKGrotesk-Bold.otf");
    font-weight: bold;
  } */

  html,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  * {
    font-family: "Poppins", "font-awesome";
  }
`;

let history = createHistory(window);
store.subscribe(() => {
  console.log("Algo Cambio:", store.getState());
});
const App = ({ onCurrentUser, ...props }) => {
  const user = useSelector(({ user }) => user);
  const filter = useSelector(({ filter }) => filter);
  const [locale, setLocale] = useState("es");
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const openNotification = ({
    title = "Notification",
    message,
    placement = "topRight"
  }) => {
    notification.info({
      message: `Notification`,
      duration: 10,
      description: message,
      placement
    });
  };
  const connect = ({ strategy = "jwt", accessToken, ...rest }) => {
    return socket.authenticate({
      strategy,
      accessToken,
      ...rest
    });
  };
  const sendFireBaseToken = async web_token => {
    const service = getService("users-devices-tokens");
    return service.create({
      web_token
    });
  };

  useEffect(() => {
    let { search, pathname } = history.location;
    let params = qs.parse(search.replace("?", ""));
    let { access_token, token } = params;
    if (access_token || token) {
      if (token && pathname === "/")
        return navigate(`/shared-documents/${token}`);
      localStorage.setItem("feathers-jwt", access_token || token);
    }
    reAuthenticate()
      .then(({ user, accessToken }) => {
        /* Socket Authentication */
        connect({
          accessToken
        });

        current()
          .then(async response => {
            try {
              let currentToken = await getToken(messaging, {
                vapidKey:
                  "BP_IhUZgGzBglv1YDkiHNYeIRv-mhL9yknOf4TO3NBWkNVi-YuwZPg1HbG64-gyrkHCasUHhALp9F2EFlb4f2fE"
              });
              if (currentToken)
                window.localStorage.setItem("firebase-token", currentToken);
              user["firebase-token"] = currentToken;
              onCurrentUser(user);
              let { pathname } = history.location;
              if (pathname === "/") navigate("/dashboard/menu-categories");
            } catch (err) {
              message.error(err.message);
            }
          })
          .catch(err => {
            message.error(err.message);
            navigate("/");
          });
      })
      .catch(err => {
        let { pathname } = history.location;
        if (pathname.includes("dashboard")) navigate("/");
      });

    return () => {};
  }, []);
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }

    OrderService.on("patched", data => {
      let message = {
        title: "Pedido entrante #" + data.id,
        message: "Nuevo pedido en " + data.restaurant.name
      };
      if (
        data.status == "new" &&
        filter &&
        filter["restaurant_id"] == data.restaurant_id
      ) {
        console.log("Order updated------------------>: ", data);
        openNotification(message);
      }
    });
    return () => {
      OrderService.off("created");
      OrderService.off("patched");
    };
  }, []);
  useEffect(() => {
    if (user && user["firebase-token"])
      sendFireBaseToken(user["firebase-token"]);
  }, [user]);
  const myRef = useRef(null);
  const ScrollToTop = ({ children, location }) => {
    useEffect(
      () => window.scrollTo(0, myRef.current ? myRef.current.offsetTop : 0),
      [location.pathname]
    );
    return children;
  };

  return (
    <I18n locale={locale} messages={messages[locale]}>
      <Provider store={store}>
        <Root>
          <Router primary={false}>
            <ScrollToTop path="/">
              <DashboardLayout initialized={initialized} path="/dashboard/*" />

              <SignIn path="/signin" />
              <SignIn path="/confirm-email/:token" />
              {/* <SignUp path="/signup" /> */}
              <ResetPassword path="/reset-password" />
              <ChangePassword path="/change_password/:token" />
              <Confirmation path="/code-confirmation" />
              <SignIn path="/" />
              {<NotFound default />}
            </ScrollToTop>
          </Router>
        </Root>
      </Provider>
    </I18n>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentUser: user =>
      dispatch({
        type: actionTypes.AUTHENTICATION,
        user
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
